export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: '#191919',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      zIndex: '1001'
    },

    '.smallNavMenu > div': {
      border: 'none',
      '.navItem': {
        color: 'white',
        a: {
          fontSize: ['1rem', '1rem', '1rem', '1rem']
        }
      }
    },

    '.navItem': {
      color: 'light',
      margin: '0rem',
      padding: ['0.5rem', '0.5rem'],
      // width: '100%',
      width: 'fit-content',
      flexGrow: ['', '', '', '1'],
      margin: ['0rem 0rem 0.5rem', '0rem 0rem 1rem', '', '0rem'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ':hover': {
        color: 'inherit'
      }
    },
    '.hamburger > div': {
      backgroundColor: 'white'
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '2rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem',
      img: {
        // filter: 'brightness(0) invert(1)',
        maxHeight: ['100px', '', '180px', '']
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'primary',
      width: ['90%', '', '40%'],
      justifyContent: ['center', 'center', 'center'],
      padding: '0rem',
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem'
      },
      '.navItem': {
        padding: ['0.75rem'],
        a: {
          fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem']
        }
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneContainer': {
      width: 'fit-content',
      filter: 'brightness(0) invert(1)'
    },
    '.addressContainer': {
      width: 'fit-content',
      filter: 'brightness(0) invert(1)'
    },
    '.socialIconsContainer': {
      width: 'fit-content',
      filter: 'brightness(0) invert(1)'
    },

    '.socialContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderLeft: '2px solid',
      paddingLeft: '0.75rem'
    },

    '.containerScrolled': {
      backgroundColor: 'black',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      zIndex: '1001',
      '.hamburger > div': {
        // backgroundColor: '#c6a34d',
      }
    },
    '.logo': {
      img: {
        opacity: '1',
        // filter: 'brightness(0) invert(1)',
        maxHeight: ['100px', '', '180px', '']
      }
    },
    '.logoScrolled': {
      img: {
        opacity: '1',

        maxHeight: ['100px', '', '180px', '']
      }
    }
  },

  footer: {
    borderTop: '1px solid',
    borderColor: 'secondary',
    backgroundColor: 'black',
    color: 'white',
    padding: '2rem 1rem 6rem',

    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'grey'
        }
      }
    },
    '.logo': {
      // filter: 'brightness(0) invert(1)',
    },

    '.sectionHeading': {
      color: 'secondary',
      fontWeight: '500',
      borderColor: 'secondary'
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(0.6)'
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'black'
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start']
    },
    '.socialContainer': {
      svg: {
        margin: '0.5rem',
        width: '40px',
        height: '40px',
        padding: '0.5rem',
        border: 'solid 1px',
        borderColor: 'secondary',
        borderRadius: '1100px',
        path: {
          fill: 'secondary'
        }
      }
    }
  },

  ctaWidget: {
    display: 'none!important',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    // borderBottom: '2px solid',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['1.75rem', '', '2.5rem'],
    textTransform: 'uppercase',
    color: 'text',
    fontWeight: 'bold',
    letterSpacing: '2px',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem'
  },

  subtitle: {
    color: 'secondary',
    fontWeight: 'normal',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'body',
    fontSize: ['1rem', '1.5rem', '1.75rem', '2rem'],
    textTransform: 'uppercase'
  },

  text: {
    order: '3',
    fontSize: '1.1rem'
  },

  forkGraphics: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '::before': {
      content: "'⑂'",
      fontSize: '3rem',
      margin: '1rem 0rem'
    },
    '::after': {
      content: "'⑂'",
      fontSize: '3rem',
      transform: 'rotate(-180deg)',
      order: '7',
      margin: '1rem 0rem'
    }
  },

  sideBySide1: {
    overflow: 'hidden',
    '.content': {
      variant: 'customVariants.forkGraphics',
      order: ['', '', '2'],
      width: ['', '', '45%'],
      padding: ['2rem 1rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text',
        textAlign: 'center',
        p: {
          textAlign: 'center'
        }
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      order: '1',
      width: ['', '', '55%'],

      height: ['', '', '80vh'],
      '::before': {
        background: 'unset'
      },
      img: {
        // height: '100vh',
        border: ['solid 3px', 'solid 3px', 'solid 5px'],
        borderColor: ['secondary', 'secondary', 'secondary'],
        boxShadow: '2px 2px 10px black'
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    overflow: 'hidden',
    flexDirection: ['', '', 'row'],
    paddingRight: ['', '', '1rem'],
    '.imageContainer': {
      order: '2',
      width: ['', '', '60%'],
      height: ['', '', '95vh'],
      position: 'relative',
      '::before': {
        content: ['none', 'none', "''"],
        border: 'solid 3px',
        borderColor: 'white',
        position: 'absolute',
        height: '85%',
        width: '50vw',
        left: '10%',
        top: '15%'
      },
      img: {
        // height: '100vh',
      },
      '.image1': {
        position: ['', '', 'absolute'],
        top: '0%',
        left: '25%',
        width: ['', '', '75%'],
        height: ['', '', '75%'],
        border: ['solid 3px', 'solid 3px', 'solid 5px'],
        borderColor: ['secondary', 'secondary', 'secondary'],
        boxShadow: '2px 2px 10px black'
      },
      '.image3': {
        position: ['', '', 'absolute'],
        bottom: '10%',
        width: ['', '', '60%'],
        height: ['', '', '50%'],
        border: ['solid 3px', 'solid 3px', 'solid 5px'],
        borderColor: ['secondary', 'secondary', 'secondary'],
        boxShadow: '2px 2px 10px black'
      }
    },

    '.content': {
      variant: 'customVariants.forkGraphics',
      order: '2',
      width: ['', '', '40%'],
      padding: ['2rem 1rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  sideBySideParallax: {
    margin: '',
    padding: '0rem',
    height: ['', '', '80vh', '80vh'],
    backgroundPosition: ['center 100%', '', 'center center'],
    backgroundSize: ['150%', '', 'cover'],
    '.section': {
      variant: 'customVariants.forkGraphics',
      width: ['', '', '40%'],
      maxWidth: 'unset',
      margin: ' 0rem 0rem 0rem auto',
      backgroundColor: 'white',
      padding: ['2rem 1rem', '', '3rem'],
      marginBottom: ['100%', '', '0rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',
      p: {
        textAlign: 'center'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      border: 'none',
      width: ['100%', '', '50%'],
      maxWidth: 'unset',
      margin: '0rem auto',
      height: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      padding: '0rem',
      fontSize: ['2rem', '2.5rem', '3.5rem', '5.5rem'],
      textShadow: '2px 2px 10px black',
      color: 'light'
    },
    '.subtitle': {
      color: 'secondary'
    },
    '.text': {
      color: 'white'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    flexDirection: ['column', 'column', 'row'],
    '.imageContainer': {
      width: ['100%', '100%', '40%'],
      maxHeight: ['', '', '400px'],
      order: ['3', '', 'unset']
    },
    '.image': {
      padding: ['0rem', '', '1rem', '2rem'],
      objectFit: 'cover'
    },
    '.content': {
      backgroundColor: ['', '', 'transparent'],
      color: 'text',
      margin: ['', '', '2rem 1rem'],
      width: ['100%', '100%', '60%'],
      border: 'solid 2px',
      borderColor: 'secondary',
      padding: ['', '', '0.5rem 5rem 0.5rem 2rem']
    },
    '.text': {
      lineHeight: '1.5',
      color: ['text', '', 'text'],
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
      letterSpacing: '1px',
      fontFamily: 'heading'
      // background: 'rgb(131,18,21)',

      // fontFamily: 'heading',
    },
    '.title': {
      width: ['3rem', '', '', '4rem'],
      textTransform: 'uppercase',
      color: ['light', '', 'light'],
      letterSpacing: '3px'
    },
    '.date': {
      color: ['text', '', 'text']
      // display: 'none',
    },

    '.shoutCTA': {
      variant: 'buttons.primary'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySideParallax'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageLocation: {
    variant: 'customVariants.sideBySideParallax'
  },

  homepageChef: {
    padding: ['15vh 1rem', '', '20vh 1rem'],
    margin: ['0rem', '', '5rem 0rem 0rem'],
    '.section': {
      width: '75%',
      textAlign: 'left',
      alignItems: 'flex-start',
      margin: '0rem 0rem 0rem 10%'
    },
    '.title': {
      variant: 'customVariants.title',
      width: '100%',
      border: 'none',
      textAlign: 'left',
      color: 'light',
      textTransform: 'capitalize',
      fontSize: ['1.5rem', '2rem', '3rem', '4rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white',
      fontFamily: 'display',
      borderTop: '8px solid',
      borderColor: 'secondary',
      paddingTop: '1.5rem'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    margin: '0rem 0rem',
    maxWidth: 'unset',
    padding: ['1rem', '', '1rem'],
    // backgroundImage:
    //   'url(https://res.cloudinary.com/gonation/image/upload/sites/cast-iron-chef-kitchen-bar/pattern-bg.png)',
    backgroundColor: '#fff8f8',
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',
    border: 'solid 4px',
    '.backToMenuBtn': {
      variant: 'buttons.secondary',
      margin: '0rem auto 2rem 0rem'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {
      width: ['100%', '', 'calc(50% - 1rem)'],
      borderRadius: '0px',
      position: 'relative'
    },
    '.allInContainerWrapper ': {
      maxWidth: '1200px',
      margin: '0rem auto'
    },
    '.cellImageContainer': {
      overflow: 'hidden'
    },
    '.cellImage': {
      borderRadius: '0px',
      transition: 'all ease-in-out 0.5s',
      ':hover': {
        transform: 'scale(1.1) translate(-50%, -50%)',
        transformOrigin: 'center center'
      }
    },
    '.menuCell': {
      borderRadius: '0px',
      '.imageFill': {
        paddingBottom: '60%'
      }
    },

    '.cellName': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'light',
      margin: '0rem',
      fontSize: ['1.5rem', '', '1.75rem', '2.5rem']
    },

    '.tabsRow': {
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      maxWidth: '1200px',
      margin: '0rem auto',
      '::before': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '3rem'],
        content: "'View The Menu'",
        margin: ['0rem 0rem 1.5rem', '', '0rem auto 0rem 0rem'],
        width: ['100%', '', '', 'fit-content'],
        letterSpacing: '0px',
        textTransform: 'capitalize',
        padding: '1rem 0rem',
        borderBottom: '3px solid'
      },
      '.menuTab': {
        fontSize: '0.9rem',
        border: 'none',
        textTransform: 'capitalize',
        padding: '0.25rem',
        letterSpacing: '-1px',
        fontFamily: 'heading',
        height: 'fit-content',
        fontWeight: '300',
        backgroundColor: 'transparent',
        margin: '1rem 0.25rem 0rem'
      },
      '.menuTabActive': {
        color: 'text',
        borderBottom: '1px solid black'
      }
    },

    '.menuContainer': {
      paddingBottom: '0rem'
    },

    '.menuSectionTitle': {
      textAlign: 'left',
      margin: '1rem 0rem 1.5rem',
      fontSize: ['1.2rem', '1.3rem', '1.4rem', '1.5rem'],
      width: 'fit-content',
      borderBottom: 'solid 1px',
      borderColor: 'secondary'
    },
    '.menuSectionDescription': {
      textAlign: 'left',
      // opacity: '0.75',
      fontSize: '0.9rem',
      color: 'secondary'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      margin: '0rem 0rem 1rem',
      padding: ['0rem', '', '0rem 1.5rem 0rem 0rem']
    },

    '.menuItemInnerContainer': {
      margin: '0rem'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },

    '.menuItemName': {
      marginBottom: '0rem',
      fontFamily: 'heading',
      fontSize: ['1.1rem', '1.2rem', '1.2rem', '1.3rem']
    },
    '.menuItemDescription': {
      color: 'secondary',
      fontSize: '1rem'
    },
    '.menuItemPrice': {
      color: 'secondary',
      fontSize: '1.1rem'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.container': {
      padding: '0rem'
    },
    '.title': {
      backgroundColor: 'secondary',
      padding: '2rem 1rem',
      textTransform: 'uppercase',
      fontSize: ['2rem', '2.5rem', '3.5rem']
    },
    '.events-container': {
      maxWidth: 'unset'
    },

    '.no-events-container': {
      img: {
        filter: 'brightness(1) invert(1)'
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      height: '100%',
      width: '100%',
      maxHeight: '350px'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    },

    '.eventItemContent': {
      width: '100%'
    },

    '.eventItemTitle': {
      borderTop: '1px solid',
      marginBottom: '1.25rem',
      paddingTop: '1rem',
      fontSize: ['1.5rem', '', '2.5rem']
    },

    '.eventItemContainer': {
      flexDirection: 'column',
      width: ['100%', 'calc(50% - 4%)'],
      border: 'solid 5px',
      borderColor: 'secondary'
    },
    '.eventItemDescription': {
      color: 'secondary'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    backgroundColor: '#fff8f8',
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'primary',
    color: 'light',
    '.content_container': {
      border: 'solid 10px',
      borderColor: 'secondary'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      color: 'secondary',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    },
    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      backgroundColor: 'secondary',
      margin: ['1rem', '', '2rem'],
      width: ['100%', 'calc(50% - 0.5rem * 2)', 'calc(33.3% - 2rem * 2)']
    },
    '.imageContainer': {
      backgroundColor: 'black',
      img: {
        objectFit: 'contain'
      }
    },
    '.title': {
      color: 'light',
      borderBottom: '2px solid #cababa'
    },
    '.subtitle': {
      color: 'light'
    }
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
