export default {
  heading: 'Din, serif',
  body: 'Yanone Kaffeesatz, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Bebas Neue',
  googleFonts: [
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Yanone Kaffeesatz:200,300,400,500,600,700,800,900',
    'Bebas Neue'
  ],
  customFamilies: ['Din'],
  customUrls: ['https://www.gonation.biz/fonts/din/din.css']
}
